const Translation = {
  app_title: "小流智算应用平台",
  system_manage_title: "Flow管理后台",
  nav: {
    home: "主页",
    flowopt: "流量流向计划应用",
    planning_sessions: "目录",
    executions: "执行记录",
    archive: "归档",
    notification: "系统通知",
    docs: "帮助中心",
    app: "应用",
    settings: "系统设置",
    user_manage: "用户管理",
    app_manage: '应用管理',
    all_workspace_manage: "工作空间设置",
    profile: "个人中心",
    system_settings: "系统数据管理",
    workspace_app_manage: "空间应用管理",
    workspace_user_manage: "成员管理",
    workspace_auth_manage: "空间授权管理",
    workspace_manage: "工作空间设置",
    auth_manage: "授权管理",
    help: "平台帮助",
    system_manage: "平台管理",
    workbench: "工作台",
    change_language: "语言切换",
    official_website: "官方网站",
    empty_app_list: "暂无可用应用",
    tip: {
      network_available: "网络正常",
      network_unavailable: "网络连接异常，请检查网络"
    }
  },
  system_manage: {
    nav: {
      dashboard: "仪表盘",
      app: "应用管理",
      app_list: "应用列表",
      auth_manage: "授权管理",
      app_permission: "应用权限",
      flow: "Flow数据",
      instance_data: "实例数据",
      sessions: "目录列表",
      workspaces: "空间列表",
      user: "用户管理",
      user_list: "用户列表",
      chart: "图表管理",
      chart_list: "图表列表",
    },
    app_list: {
      app: "应用",
      title: "应用管理",
      app_id: "应用ID",
      app_manifest: "应用配置",
      name: "应用名称",
      app_type: "类型",
      app_type_private: "私有",
      app_type_public: "全局",
      workspace: "使用空间",
      owner: "创建人",
      description: "应用描述",
      version: "应用版本",
      active: "状态",
      active_true: "启用",
      active_false: "停用",
      created_at: "创建时间",
      updated_at: "更新时间",
      private_workspaces: "分配空间",
      action: {
        register: "注册应用",
        enable: "启用",
        disable: "停用",
      },
      tip: {
        register: "注册应用{{status}}！",
        update: "更新应用{{status}}！",
      },
      edit_pricing: "编辑价格",
    },
    app_permission: {
      title: "应用权限",
      workspace: "所属空间",
      created_at: "创建时间",
      company: "所属公司",
      workspace_name: "空间名称",
      app_name: "应用名称",
      app_from_time: "应用开始时间",
      app_to_time: "应用结束时间",
      app_use_time: "应用使用时间",
      tip: {
        create: "新增权限{{status}}！",
        save: "保存权限{{status}}！",
        app_workspace_exists: "应用已配置",
        no_app_use_time: "未配置应用使用时间",
        expired: "应用已到期，请及时购买",
      },
    },
    instance_data: {
      workspace: "所属空间",
      app: "应用",
      user: "所属用户",
      created_at: "创建时间",
      instance_num: "实例数",
      total_solve_time: "总求解时长",
      total_solve_num: "总求解次数",
      min_solve_time: "最短求解时长",
      max_solve_time: "最长求解时长",
      ave_solve_time: "平均求解时长",
      session_num: "目录数",
      s: "个",
      minute: "分钟",
      times: "次",
    },
    user_list: {
      title: "管理员管理",
      user: "管理员",
      user_id: "ID",
      email: "邮箱",
      account: "账户",
      is_superuser: "超级管理员",
      active: "状态",
      active_true: "可用",
      active_false: "不可用",
      created_at: "注册时间",
      username: "用户名",
      workspace: "所属空间",
      last_login: "最后登录时间",
      created_by: "邀请人",
      tip: {
        update: "更新管理员{{status}}！",
      }
    },
    chart_list: {
      title: "图表",
      app: "所属应用",
      chart_name: "图表名称",
      app_name: "应用名称",
      created_by: "创建人",
      created_at: "创建时间",
      set_as_template: "设为模板",
      tip: {
        create: "新增图表{{status}}！",
        save: "保存图表{{status}}！",
      },
    },
  },
  home: {
    welcome: "欢迎使用小流智算",
    guide: "操作向导",
    guide_video: "小流智算快速入门视频",
    introduction: "简介",
    all_apps: "所有应用",
    recently_viewed: "最近浏览",
    app_blank: "当前工作空间暂无应用，请开通相关应用"
  },
  instance: {
    default_session: "新建目录",
    session: "所属目录",
    app: "所属应用",
    run_instance: "文件",
    info: "文件信息",
    input_view: "输入视图",
    output_view: "输出视图",
    chart_view: "图表操作",
    analysis_view: "数据分析",
    name: "文件名称",
    shared: "分享",
    sharer: "分享人",
    shared_time: "分享时间",
    permission: "权限",
    description: "文件描述",
    created_at: "创建时间",
    readiness_status: "创建状态",
    solving_status: "求解状态",
    actions: "文件操作",
    init_import: "初始化导入",
    excel: "EXCEL文件",
    upload_file: "上传文件",
    excel_template: "EXCEL模版",
    upload_tip: "请在下载的Excel模板里输入数据，然后上传",
    upload: {
      dragger: "将文件拖拽到这里或者点击此区域开始导入",
      accept: "支持 xlsx 格式",
    },
    owner: "拥有者",
    locked: "已锁定",
    unlocked: "未锁定",
    default_memory: "默认设置",
    memory_4G: "4G内存",
    memory_8G: "8G内存",
    memory_12G: "12G内存",
    share_instance: "分享文件",
    members: "当前成员",
    can_manage: "可管理",
    only_visit: "可查看",
    only_visit_instance: "只可查看该文件内容",
    can_manage_instance: "已拥有管理该文件的所有操作权限",
    remove_member: "移除成员",
    action: {
      archive: "归档",
      copy: "复制",
      create: "新建文件",
      upload: '点击选择文件上传',
      solve: "求解模型",
      export_input: "导出数据",
      export_output: "导出数据",
      share: "分享",
      update_data: "更新数据",
      unarchive: "复原",
      info_update: "更新文件信息",
      copy_instance_id: "复制ID",
      lock_instance: "锁定",
      unlock_instance: "解锁",
      copy_instance: "复制",
      share_instance: "分享",
      delete_instance: "删除",
      select_memory_request: "内存规格：",
      compare: "对比",
      compare_title: "文件对比",
      file_name: "文件名称",
      view_type: "视图类型",
      compare_table_name: "对比表名",
      only_changed: "只显示变化数据",
      only_greater_than_0: "只显示大于0数据",
      show_diff_value: "显示差异量",
      show_diff_ratio: "显示差异比例",
    },
    tip: {
      archive: "{{count}}个文件归档{{status}}!",
      cannot_copy: "该文件创建状态未成功，暂不可复制！",
      save: "保存文件{{status}}！",
      create: "创建文件{{status}}！",
      upload_blank: '请上传文件或文件上传尚未完成',
      copy: "复制文件{{status}}！",
      upload_fail: "文件上传失败, 请重新上传",
      get_info_fail: "无法获取文件信息，请稍后重试",
      solve: "提交模型求解任务{{status}}！",
      unarchive: "{{count}}个文件复原{{status}}!",
      copy_instance_id: "文件ID复制{{status}}!",
      lock_instance: "锁定文件{{status}}!",
      unlock_instance: "解锁文件{{status}}!",
      rename_instance: "重命名文件{{status}}!",
      delete_instance: "删除文件{{status}}!",
      get_members_fail: "获取成员失败",
      get_users_fail: "获取用户失败",
      update_members: "编辑权限{{status}}!",
      compare_count_error_overflow: "只支持选择两个文件进行对比操作",
      compare_count_error_lack: "需要选中两个文件进行对比操作",
      compare_count_error_status: "只支持创建成功的文件",
      not_a_number: "你输入的不是一个正确的数字格式",
    },
    confirm: {
      archive: "是否确定进行文件归档?",
      solve: "是否确定要求解模型?",
      export_input: "是否确定导出输入数据?",
      export_output: "是否确定导出输出数据?",
      unarchive: "是否确定进行文件复原?",
      save_update: "是否确定保存数据?",
      delete: "是否确定删除文件?",
    }
  },
  chart_view: {
    title: "图表",
    name: "名称",
    select_dataframe: "选择表",
    is_template: "是否设定为模板",
    select_chart_type: "选择图表类型",
    count_records_label: "记录总数",
    count_records: "统计记录总数",
    count_a_field: "统计指定字段",
    select_filed: "请选择统计指定字段",
    sum: "求和",
    min: "最小值",
    max: "最大值",
    average: "平均值",
    empty_stacked_by: "[无]",
    sort_by_x: "按 X 轴的值排序",
    sort_by_y: "按 Y 轴的值排序",
    sort_rule_ascending: "按正序排序",
    sort_rule_descending: "按倒序排序",
    more_settings: "更多配置",
    color_picker: "选择配色方案",
    multi_color_theme: "多色主题",
    monochrome_gradient_theme: "单色渐变主题",
    theme_color_1: "配色1",
    theme_color_2: "配色2",
    theme_color_3: "配色3",
    theme_color_4: "配色4",
    theme_color_5: "配色5",
    theme_color_6: "配色6",
    theme_color_7: "配色7",
    theme_color_8: "配色8",
    chart_type_enum: {
      heatmap: "热力图",
      map: "地图",
      location_relationship: "地理位置关系图",
      column_chart: "柱状图",
      stacked_column_chart: "堆叠柱状图",
      percentage_column_chart: "百分比柱状图",
      bar_chart: "条形图",
      stacked_bar_chart: "堆叠条形图",
      percentage_bar_chart: "百分比条形图",
      pie_chart: "饼状图",
      donut_chart: "环状图",
      line_chart: "折线图",
      stacked_line_chart: "堆叠折线图",
      scatter_plot: "散点图",
      funnel_chart: "漏斗图",
    },
    config: {
      pie_chart: {
        value: "数值",
        dimension: "维度",
        text_size: "文本大小",
      },
      donut_chart: {
        value: "数值",
        dimension: "维度",
        text_size: "文本大小",
      },
      bar_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段分组",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      stacked_bar_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段堆叠",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      percentage_bar_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段百分比堆叠",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      line_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段分组",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      stacked_line_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段堆叠",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      column_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段分组",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      stacked_column_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段堆叠",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      percentage_column_chart: {
        dimension: "维度（X 轴）",
        value: "数值（Y 轴）",
        stacked_by: "按下方字段百分比堆叠",
        sort_by: "选择一个坐标轴排序",
        sort_rule: "设置排序规则",
        text_size: "文本大小",
      },
      gaode_heatmap: {
        opacity: "整体透明度",
        show_tip: "是否显示热力值标注",
        unit: "热力的单位",
        radius: "热力的聚合半径",
        colors: "配色方案",
        max: "热力值的最大值",
        min: "热力值的最小值",
        difference: "是否关闭颜色过渡",
        is3d: "是否开启3d效果",
        px: "像素",
        meter: "地理真实的距离(米)",
        latitude: '纬度字段',
        longitude: '经度字段',
        value: '值字段',
      },
      location_relationship: {
        opacity: "整体透明度",
        show_tip: "是否显示名称标注",
        main_latitude: '主点纬度字段',
        main_longitude: '主点经度字段',
        sub_latitude: '子点纬度字段',
        sub_longitude: '子点经度字段',
        main_name: '主点名称字段',
        sub_name: '子点名称字段',
        main_size: '主点尺寸',
        sub_size: '子点尺寸',
        line_width: '线宽',
      },
      map_type: {
        title: "请选择地图类型",
        default: "默认地图",
        gaode: "高德地图",
        baidu: "百度地图",
        input_key: "填写应用的Key",
      },
    },
    action: {
      add: "新增图表",
    },
    tip: {
      create: "创建图表{{status}}!",
      update: "更新图表{{status}}!",
      delete: "删除图表{{status}}!",
      table_blank: "请选择表",
    },
    confirm: {
      delete: "是否确定删除图表?",
    }
  },
  dataframe_filter: {
    filters_count: "{{count}}个筛选条件",
    filter: "筛选",
    title: "设置筛选",
    when: "当",
    and: "并且",
    or: "或者",
    equal: "等于",
    unequal: "不等于",
    include: "包含",
    except: "不包含",
    null: "为空",
    not_null: "不为空",
    duplicate: "有重复",
    greater_than: "大于",
    greater_than_or_equal_to: "大于等于",
    less_than: "小于",
    less_than_or_equal_to: "小于等于",
    yes: "是",
    no: "否",
    add_filter: "添加筛选条件",
  },
  task_execution: {
    execution_records: "执行记录",
    execution: '文件操作',
    process_id: "任务标识",
    process_type: "任务类型",
    created_at: "创建时间",
    finished_at: "完成时间",
    started_at: "开始时间",
    duration: "执行时长",
    process_job_status: "执行状态",
    info: "任务详情",
    remark: "备注",
    status_enum: {
      failed: "失败",
      succeeded: "成功",
      running: "运行中",
      pending: "等待中",
      canceled: "取消",
      error: "异常",
    },
    process_type_enum: {
      data_clone: "数据复制",
      data_import: "数据导入",
      solve: "模型求解",
    },
    log: {
      title: "日志",
      name: "子任务名称",
      id: "子任务标识",
      status: "子任务状态",
      started_at: "子任务开始时间",
      finished_at: "子任务完成时间",
      info: "日志信息"
    },
    confirm: {
      cancel_solve: "是否取消求解?",
    },
    tip: {
      get_info_fail: "无法获取日志信息，请稍后重试",
      save_remark_success: "保存备注成功",
      save_remark_fail: "保存备注失败",
      cancel_solve_success: "取消求解成功",
      cancel_solve_fail: "取消求解失败",
    }
  },
  session: {
    instance_list: "文件列表",
    info: "目录信息",
    planning_session: "目录",
    all_planning_sessions: "所有目录",
    all_run_instances: "所有文件",
    all_archive_sessions: "所有归档目录",
    all_archive_instance: "所有归档文件",
    name: "目录名称",
    description: "目录描述",
    created_at: "创建时间",
    actions: "目录操作",
    action: {
      archive: "归档目录",
      create: "新建目录",
      unarchive: "复原目录",
      info_update: "更新目录信息",
      copy_session_id: "复制目录ID",
      delete: "删除",
    },
    tip: {
      archive: "{{count}}个目录归档{{status}}!",
      save: "保存目录{{status}}！",
      create: "新建目录{{status}}！",
      unarchive: "{{count}}个目录复原{{status}}!",
      copy_session_id: "目录ID复制{{status}}!",
      rename_session: "重命名目录{{status}}！",
      delete_session: "删除目录{{status}}！",
      contains_instance: "当前目录下有文件，不能删除",
      delete_default: "默认目录，不允许删除",
    },
    confirm: {
      archive: "是否确定进行目录归档?",
      unarchive: "是否确定进行目录复原?",
      delete: "是否确定删除?"
    }
  },
  profile: {
    title: '账号信息',
  },
  user_manage: {
    title: "管理员管理",
    user: "管理员",
    user_id: "ID",
    email: "邮箱",
    account: "账户",
    is_superuser: "超级管理员",
    active: "状态",
    active_true: "可用",
    active_false: "不可用",
    created_at: "注册时间",
    username: "用户名",
    workspace: "所属空间",
    last_login: "最后登录时间",
    created_by: "邀请人",
    tip: {
      update: "更新管理员{{status}}！",
    }
  },
  app_manage: {
    app: "应用",
    title: "应用管理",
    app_id: "应用ID",
    app_manifest: "应用配置",
    name: "应用名称",
    app_type: "类型",
    app_type_private: "私有",
    app_type_public: "全局",
    workspace: "使用空间",
    owner: "创建人",
    description: "应用描述",
    version: "应用版本",
    active: "状态",
    active_true: "启用",
    active_false: "停用",
    created_at: "创建时间",
    updated_at: "更新时间",
    private_workspaces: "分配空间",
    action: {
      register: "注册应用",
      enable: "启用",
      disable: "停用",
    },
    tip: {
      register: "注册应用{{status}}！",
      update: "更新应用{{status}}！",
    }
  },
  sheet: {
    updating: "更新中",
    decimal_format: "设置小数点",
    add_row: "新增一行",
    tip: {
      update_success: "后台数据更新成功！",
      update_fail: "无法更新后台数据，请检查数据格式是否正确"
    }
  },
  workspace: {
    switch: "切换工作空间",
    current: "工作空间",
    add: "创建工作空间",
    new: "新建工作空间",
    change: "修改名称",
    name: "工作空间名称",
    create_title: "创建你的工作空间",
    no_permission: "您没有当前空间的权限",
    quit: "退出空间",
    cannot_quit: "空间所有者无法直接退出空间",
    confirm_quit_content: `<p>已选择空间： <b>{{workspace}}</b></p>
    <p>退出后，你将无法进入当前空间，请谨慎操作</p>`,
    confirm_quit: "确认退出",
    tips: {
      create: "创建工作空间{{status}}！",
      update: "修改{{status}}！",
      set_default: "切换工作空间{{status}}！",
      quit: "退出空间{{status}}！",
      no_workspace: "您当前没有可用的空间，请联系管理员",
    }
  },
  workspace_app_manage: {
    title: "空间应用管理",
    config: "设置",
    baidu_map_key: "百度地图key",
    gaode_map_key: "高德地图key",
    enable_custom_fields: "开启文件自定义字段",
    enable_callback: "开启文件状态回调",
    callback_url_import: "导入状态回调地址",
    callback_url_solve: "求解状态回调地址",
    custom_field: {
      title: "自定义字段",
      name: "字段名称",
      identifier: "标识符",
      type: "字段类型",
      required: "必填",
      is_field_required: "字段是否为必填项",
      enum_values: "枚举值",
      type_enum: {
        MONTH: "日期-月份",
        DATE: "日期-天",
        DATETIME: "日期时间",
        TEXT: "文本",
        NUMBER: "数字",
        BOOLEAN: "布尔值",
        ENUM: "枚举",
      }
    },
    actions: {
      add_field: '添加字段',
      copy_app_key: '复制应用密钥',
    },
    tips: {
      update: "更新空间应用{{status}}！",
      custom_fields: {
        name_error: "字段名仅支持中英文和常见字符",
        identifier_tip: "字段标识名将作为字段使用的标识，仅支持大小写字母、下划线",
        identifier_error: "仅支持大小写字母、下划线",
        existed: "{{name}}已存在，请重新输入",
        config: "设置{{status}}",
      },
      copy_app_key: '复制应用密钥{{status}}！',
    },
    confirm: {
      active_false: "此应用停用后，此空间将无可用应用，是否确定停用？"
    }
  },
  workspace_user_manage: {
    title: "空间用户管理",
    user: "成员",
    email: "邮箱",
    account: "账户",
    active: "状态",
    created_at: "创建时间",
    username: "用户名",
    created_by: "邀请人",
    logined: {
      title: "激活状态",
      active: "已激活",
      inactive: "未激活",
    },
    member_type: {
      title: "成员类型",
      OWNER: "拥有者",
      ADMIN: "管理员",
      MEMBER: "普通成员"
    },
    action: {
      add: "邀请新成员",
    },
    tips: {
      add: "新增空间成员{{status}}！",
      update: "更新空间成员{{status}}！",
      delete: "移除空间成员{{status}}！",
      name_length_invalid: "名称最多不能超过50个字",
      email_format_invalid: "请输入正确的邮箱",
      email_existed: "该邮箱已经添加",
      user_existed: "该用户已存在",
      confirm_delete: "移除后，该用户创建的内容仍将保留，是否确定移除当前用户？",
    }
  },
  workspace_auth_manage: {
    title: "空间授权管理",
    workspace_id: "空间ID：",
    name: "终端名称",
    client: "授权",
    client_admin: "管理权限",
    token: "令牌",
    expired_at: "到期时间",
    active: "状态",
    token_status: {
      title: "令牌状态",
      expired: "已过期",
      available: "有效",
    },
    action: {
      add: "新增授权",
      refresh_token: "刷新令牌",
      copy: "复制token",
      copy_workspace: "复制空间ID",
      copy_app_id: "复制应用ID",
    },
    tips: {
      add: "新增空间授权{{status}}！",
      update: "更新空间授权{{status}}！",
      delete: "删除空间授权{{status}}！",
      refresh_token: "刷新空间授权令牌{{status}}！",
      copy: "复制token{{status}}！",
      copy_workspace: "复制空间ID{{status}}！",
      copy_app_id: "复制应用ID{{status}}！",
      save_token: "请小心保存并确保不被泄露。一旦关闭，您将无法再次从系统中获取",
    }
  },
  platform_auth_manage: {
    title: "平台授权管理",
    name: "名称",
    client: "授权",
    token: "令牌",
    expired_at: "到期时间",
    active: "状态",
    token_status: {
      title: "令牌状态",
      expired: "已过期",
      available: "有效",
    },
    action: {
      refresh_token: "刷新令牌",
      copy: "复制令牌",
    },
    tips: {
      refresh_token: "刷新授权令牌{{status}}！",
      copy: "复制授权令牌{{status}}！",
    }
  },
  notification: {
    popover: {
      app: "应用",
      workspace: "空间",
      platform: "平台",
      read_all: "全部已读",
      release_note_title: "发现新版本",
    },
    app: "应用通知",
    workspace: "空间通知",
    system: "平台通知",
    all: "全部",
    read: "已读",
    unread: "未读",
  },
  common: {
    version: "版本",
    success: "成功",
    fail: "失败",
    pending: "准备中",
    running: "进行中",
    keyword_search: "关键字搜索",
    keyword_search_execution_records: "输入关键字对文件名称，文件描述进行搜索",
    keyword_search_planning_sessions: "输入关键字对目录名称，目录描述进行搜索",
    keyword_search_run_instances: "输入关键字对文件名称，文件描述进行搜索",
    cancel: "取消",
    close: "关闭",
    create: "创建",
    save: "保存",
    update: "更新",
    operation_invalid: "该操作无效!",
    operation_abnormal: "操作异常，请重试！",
    archive: "归档",
    empty: "暂无详情",
    actions: "操作",
    edit: "编辑",
    new: "新增",
    delete: "删除",
    remove: "移除",
    copy: "复制",
    no_permission: "暂无权限",
    loading: "加载中, 请耐心等待...",
    logout: "退出",
    change_workspace: "转换工作空间",
    login: "登录",
    search: "搜索",
    open_app: "打开应用",
    start_to_search: "请输入内容进行搜索",
    app_header_search: "搜索文件/目录",
    true: "是",
    false: "否",
    assistant: "Flow助手",
    add: "添加",
    comfirm: "确定",
    type: "类型",
    is_blank: "{{name}}不可为空",
    change: "修改",
    first: "最顶",
    previous: "上一个",
    next: "下一个",
    last: "最末",
    check_all: "全选",
    list: '列表',
    card: '卡片',
    total: "共 {{count}} 条",
    settings: "设置",
    manage: "管理",
    all: "全部",
    reset: "重置",
    query: "查询",
    red: "红色",
    pink: "粉红色",
    purple: "紫色",
    deep_purple: "深紫色",
    indigo: "靛青",
    blue: "蓝色",
    light_blue: "浅蓝色",
    cyan: "青色",
    teal: "蓝绿色",
    green: "绿色",
    light_green: "浅绿色",
    lime: "酸橙色",
    yellow: "黄色",
    amber: "琥珀色",
    orange: "橙色",
    deep_orange: "深橙色",
    brown: "棕色",
    grey: "灰色",
    blue_grey: "蓝灰色",
    server_error_tips: "请稍后尝试或者联系客服人员",
    permanent: "永久",
    confirm_delete: "是否确定删除？",
    refresh: "刷新",
    full_screen: "全屏",
    exit_full_screen: "退出全屏",
    update_tip: "修改{{status}}！",
  },
  supply_chain_ai_platform: {
    app_title: "智能供应链平台",
    welcome: "欢迎使用智能供应链平台",
    guide_video: "智能供应链快速入门视频",
  },
  payment: {
    request_payment_title: "已经抵达表格条目限额",
    request_payment: "当前上传数据已超过免费求解范围，请联系我们进行扩容",
    go_to_payment: "转至支付",
    fetch_pricing_fail: "获取定价信息失败",
    create_checkout_fail: "创建支付会话失败",
    success_title: "支付成功",
    success_message: "您的支付已成功处理！",
    session_id: "会话 ID",
    cancel_title: "支付已取消",
    cancel_message: "您的支付已取消。",
    go_to_dashboard: "返回控制台",
    go_to_homepage: "返回首页",
    choose_plan_title: "选择支付计划",
    monthly_price: "月付计划",
    yearly_price: "年付计划",
    choose_monthly_plan: "选择月付计划",
    choose_yearly_plan: "选择年付计划",
    loading_pricing: "正在加载定价信息...",
    per_month: "/ 月",
    per_year: "/ 年",
    error_title: "支付异常",
  },
  pricing: {
    modal_title: "设置",
    monthly: "月付价格",
    yearly: "年付价格",
    currency: "美元",
    monthly_required: "请输入月付价格",
    yearly_required: "请输入年付价格",
    table_name: "表名称",
    row_limit: "限制行数",
    action: "操作",
    delete: "删除",
    cancel: "取消",
    save: "保存",
    add_row: "添加行",
    save_success: "定价保存成功",
    save_fail: "保存定价失败"
  }
}

export default Translation;
