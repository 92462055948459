import { CustomFieldConfig } from '../interfaces/app';
import { useTranslation } from 'react-i18next';
import { Form, Input, InputNumber, DatePicker, Select } from 'antd';

type Props = {
    name: string;
    field: CustomFieldConfig;
}

const CustomFieldFormItem = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Form.Item
            name={props?.name}
            label={props?.field?.name}
            rules={[
                ...(!!props?.field?.required ? [{ required: true, message: '' }] : []),
                ...(props?.field?.type === 'ENUM' ? [({ getFieldValue }) => ({
                    validator: (_, value) => {
                        if(!props?.field?.required && !value) {
                            return Promise.resolve();
                        }
                        if (!!value && !!props?.field?.values?.includes(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(
                            new Error("")
                        );
                    },
                })] : []),
            ]}>
            {
                props?.field?.type === 'MONTH' ? <DatePicker picker="month" style={{width: '100%'}} format={'YYYY-MM'} /> :
                props?.field?.type === 'DATE' ? <DatePicker style={{width: '100%'}} format={'YYYY-MM-DD'} /> :
                props?.field?.type === 'DATETIME'? <DatePicker showTime style={{width: '100%'}} format={'YYYY-MM-DD HH:mm:ss'} /> :
                props?.field?.type === 'NUMBER' ? <InputNumber style={{width: '100%'}} /> :
                props?.field?.type === 'ENUM' ?  <Select
                    style={{ width: '100%' }}
                    options={(props?.field?.values || []).map((value) => ({
                        label: value,
                        value: value
                    }))}
                    allowClear={true}
                /> :
                props?.field?.type === 'BOOLEAN' ? <Select
                    style={{ width: '100%' }}
                    options={[
                        { label: t('common.true'), value: true },
                        { label: t('common.false'), value: false },
                    ]}
                /> :
                <Input showCount maxLength={100} />
            }
        </Form.Item>
    )
}

export default CustomFieldFormItem;